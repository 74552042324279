import React, { useEffect, useState } from 'react';
import { DatePicker, Button, Select, CheckboxToggle } from 'react-rainbow-components';
import useGlobal from '../../../store';
import Tile from '../../../components/Tile';
import { Badge, Card, Chart, Dataset } from 'react-rainbow-components';

const ChartsSection = (props) => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [selectedBuilding, setSelectedBuilding] = useState(0);
    const [buildingList,] = useState([
        { value: 0, label: 'All Locations'},
        { value: 1, label: 'Washington'},
        { value: 5, label: 'Washington - Big Box'},
        { value: 20, label: 'Uniontown'},
        { value: 14, label: 'Bridgeport'},
        { value: 2, label: 'Butler'},
        { value: 21, label: 'Butler - Yinz'},
        { value: 3, label: 'McKees Rocks'},
        { value: 16, label: 'Monroeville'},
        { value: 15, label: 'Robinson'},
        { value: 4, label: 'Beaver Falls'},
        { value: 6, label: 'Pittsburgh Mills'},
        { value: 8, label: 'Pittsburgh Mills - Big Box'},
        { value: 10, label: 'Boardman'},
        { value: 12, label: 'Akron'},
        { value: 11, label: 'Warren'},
        { value: 22, label: 'Canton'},
        { value: 9, label: 'Spartanburg'},
        { value: 13, label: 'Greenville'},
        { value: 17, label: 'Rock Hill'},
        { value: 18, label: 'Gastonia'},
        { value: 19, label: 'Anderson'},
        { value: 23, label: 'Schertz'},
        { value: 24, label: 'Henderson'},
        { value: 27, label: 'North Las Vegas'},
        { value: 25, label: 'Phoenix'},
        { value: 28, label: 'Mesa'},
        { value: 27, label: 'Colorado Springs'}

    ]);
    const [showRetail, setShowRetail] = useState(false);

    const [breakdownChartLabels, setBreakdownChartLabels] = useState([]);
    const [breakdownChartData, setBreakdownChartData] = useState([]);
    const [breakdownChartRecoveryData, setBreakdownChartRecoveryData] = useState([]);
    const [breakdownChartBidData, setBreakdownChartBidData] = useState([]);
    const [breakdownChartAvgBidData, setBreakdownChartAvgBidData] = useState([]);

    const [chartLabels, setChartLabels] = useState([]);
    const [chartCostData, setChartCostData] = useState([]);
    const [chartRevenueData, setChartRevenueData] = useState([]);
    const [chartProfitData, setChartProfitData] = useState([]);
    const [chartRetailData, setChartRetailData] = useState([]);
    const [chartRecoveryData, setChartRecoveryData] = useState([]);
    const [chartCostRateData, setChartCostRateData] = useState([]);

    const [totalAuctions, setTotalAuctions] = useState(0);
    const [totalAuctionInvoiced, setTotalAuctionInvoiced] = useState(0);
    const [avgAuctionCost, setAvgAuctionCost] = useState(0);
    const [avgAuctionProfit, setAvgAuctionProfit] = useState(0);
    const [totalAuctionCost, setTotalAuctionCost] = useState(0);
    const [totalAuctionRevenue, setTotalAuctionRevenue] = useState(0);
    const [totalAuctionProfit, setTotalAuctionProfit] = useState(0);
    const [avgAuctionRevenue, setAvgAuctionRevenue] = useState(0);
    const [totalROI, setTotalROI] = useState(0);
    const [totalLots, setTotalLots] = useState(0);
    const [avgLotRevenue, setAvgLotRevenue] = useState(0);
    const [avgAuctionRetail, setAvgAuctionRetail] = useState(0);
    const [avgRecovery, setAvgRecovery] = useState(0);


    const [includeSpecials, setIncludeSpecials] = useState(true);
    const [includePallets, setIncludePallets] = useState(true);

    const [auctionData, auctionActions] = useGlobal(
        state => state.auctionData,
        actions => actions.auctionActions
    );
    const [auctionLocations] = useGlobal(
        state => state.auctionLocations
    );
    const [retailBreakdownData] = useGlobal(
        state => state.retailBreakdownData
    );

    useEffect(() => {
        if(!auctionLocations || !auctionData) {
            return;
        }
        let tAuctions = auctionLocations.reduce((pv, cv) => pv + (cv.total_auctions * 1), 0);
        setTotalAuctions(tAuctions);

        let tNonPalletAuctions = auctionData.filter(a => a.auction_type !== "pallet").length;

        let tCost= auctionLocations.reduce((pv, cv) => pv + (cv.total_cost * 1), 0);;
        setTotalAuctionCost('$' + tCost.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        let tRevenue = auctionLocations.reduce((pv, cv) => pv + (cv.total_revenue * 1), 0);
        setTotalAuctionRevenue('$' + tRevenue.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        let tInvoiced = auctionLocations.reduce((pv, cv) => pv + (cv.total_invoiced * 1), 0);
        setTotalAuctionInvoiced('$' + tInvoiced.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        let tLots = auctionLocations.reduce((pv, cv) => pv + (cv.total_lots * 1), 0);
        setTotalLots(tLots.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        let tProfit= auctionLocations.reduce((pv, cv) => pv + ((cv.total_revenue * 1) - (cv.total_cost * 1)), 0);
        setTotalAuctionProfit('$' + tProfit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        let tRetail= auctionLocations.reduce((pv, cv) => pv + (cv.total_retail * 1), 0);

        let tROI = ((tRevenue - tCost) / tCost * 100).toFixed(2) + '%'
        setTotalROI(tROI);

        let avg = '$' + (tCost / tNonPalletAuctions).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgAuctionCost(avg);
        avg = '$' + (tRevenue / tNonPalletAuctions).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgAuctionRevenue(avg);
        avg = '$' + (tRevenue / tLots).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgLotRevenue(avg);

        avg = '$' + ((tRevenue / tNonPalletAuctions) - (tCost / tNonPalletAuctions)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgAuctionProfit(avg);

        avg = '$' + (tRetail / tLots).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAvgAuctionRetail(avg);

        let recoveryRate = (tRevenue / tRetail * 100).toFixed(2) + '%';
        setAvgRecovery(recoveryRate);


    }, [auctionLocations, auctionData]);

    useEffect(() => {
        if(!retailBreakdownData) {
            return;
        }
        let lbls = retailBreakdownData.map(rb => {
            if(rb.retail_price_category === "0") {
                return "N/A";
            } else if (rb.retail_price_category === "10001") {
                return "$10,000 +";
            } else {
                return "$" + rb.retail_price_category;
            }
        });
        setBreakdownChartLabels(lbls);
        let data = retailBreakdownData.map(rb => rb.total_lots);
        setBreakdownChartData(data);
        data = retailBreakdownData.map(rb => rb.avg_recovery * 100);
        setBreakdownChartRecoveryData(data);
        data = retailBreakdownData.map(rb => rb.total_bid_value);
        setBreakdownChartBidData(data);

        data = retailBreakdownData.map(rb => {
            if(rb.total_lots === "0") {
                return 0;
            }
            return (rb.total_bid_value * 1) / (rb.total_lots * 1);
        })
        setBreakdownChartAvgBidData(data);

    }, [retailBreakdownData]);

    useEffect(() => {

        let n = Date.now();
        let e = formatDate(n - (2 * 24 * 60 * 60 * 1000));
        let s = formatDate(n - (31 * 24 * 60 * 60 * 1000));

        auctionActions.getAuctionLocations(s, e);
        auctionActions.getAuctionData(s, e);
        auctionActions.getRetailBreakdownData(s, e);
    }, [auctionActions]);

    const getStartOfWeek = (d) => {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day; 
        
        let sunday = new Date(d.setDate(diff))
        return sunday.getFullYear() + '-' + (sunday.getMonth()+1) + '-' + sunday.getDate();
    }

    useEffect(() => {
        if(!auctionData) {
            return;
        }
        let data = {
            days: [],
            auctions: [],
            costs: [],
            revenues: [],
            profits: [],
            retails: [],
            recoveryRates: [],
            costRates: []
        };
        for(let i = 0; i < auctionData.length; i++) {
            if(auctionData[i].auction_type === "pallet") {
                continue;
            }
            let dtRec = auctionData[i].closing_date.split(" ")[0];
            dtRec = getStartOfWeek(dtRec);
            let dtIdx = data.days.indexOf(dtRec);
            if(dtIdx < 0) {
                data.days.push(dtRec);
                data.auctions.push(1);
                data.costs.push(auctionData[i].total_cost * 1);
                data.revenues.push(auctionData[i].total_revenue * 1);
                data.profits.push( (auctionData[i].total_revenue * 1) - (auctionData[i].total_cost * 1) );
                data.retails.push(auctionData[i].total_retail * 1);
            } else {
                data.auctions[dtIdx]++;
                data.costs[dtIdx] = data.costs[dtIdx] + (auctionData[i].total_cost * 1);
                data.revenues[dtIdx] = data.revenues[dtIdx] + (auctionData[i].total_revenue * 1);
                data.profits[dtIdx] = data.profits[dtIdx] + (auctionData[i].total_revenue * 1) - (auctionData[i].total_cost * 1);
                data.retails[dtIdx] = data.retails[dtIdx] + (auctionData[i].total_retail * 1);
            }
        }
        for(let j=0; j < data.days.length; j++) {
            //data.costs[j] = parseInt(data.costs[j] / data.auctions[j], 10);
            //data.revenues[j] = parseInt(data.revenues[j] / data.auctions[j], 10);
            //data.profits[j] = parseInt(data.profits[j] / data.auctions[j], 10);
            //data.retails[j] = parseInt(data.retails[j] / data.auctions[j], 10);
            data.recoveryRates.push(data.revenues[j] / data.retails[j] * 100);
            data.costRates.push(data.costs[j] / data.retails[j] * 100);
        }

        setChartLabels(data.days.reverse());
        setChartCostData(data.costs.reverse());
        setChartRevenueData(data.revenues.reverse());
        setChartProfitData(data.profits.reverse());
        setChartRetailData(data.retails.reverse());
        setChartRecoveryData(data.recoveryRates.reverse());
        setChartCostRateData(data.costRates.reverse());
    }, [auctionData]);
    

    const handleClick = () => {
        console.log('click', startDate, endDate, includeSpecials, includePallets);
        let s = formatDate(startDate);
        let e = formatDate(endDate);

        auctionActions.getAuctionLocations(s, e, selectedBuilding, includeSpecials, includePallets);
        auctionActions.getAuctionData(s, e, selectedBuilding, includeSpecials, includePallets);
        auctionActions.getRetailBreakdownData(s, e, selectedBuilding, includeSpecials, includePallets);
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    useEffect(() => {
        const beginning = props.match.params.start ?
            new Date(new Date(props.match.params.start).getTime() + 5 * 60 * 60 * 1000)
            : new Date(new Date().getTime() - (31 * 24 * 60 * 60 * 1000));
        const ending = props.match.params.end ?
            new Date(new Date(props.match.params.end).getTime() + 5 * 60 * 60 * 1000) 
            : new Date(new Date().getTime() - (2 * 24 * 60 * 60 * 1000));
        setStartDate(beginning);
        setEndDate(ending);
    }, []);

    const containerStyles = {
        width: 300,
    };

    const handleSelect = (e) => {
        console.log(e.target, e.target.value);
        setSelectedBuilding(e.target.value);
    }

    

    const toggleRetail = ()=> {
        console.log('toggle Retail');
        setShowRetail(!showRetail);
    }

    return (<>
    <section className="react-rainbow-admin-orders_cards-container">
        <div style={{display:"flex", alignItems:"center"}}>
                <div className="date-range">
                    <DatePicker
                        id="datePicker1"
                        value={startDate}
                        onChange={(value) => setStartDate(value)}
                        label="Start Date (Auction Close)"
                        formatStyle="medium"
                    />
                    <DatePicker
                        id="datePicker2"
                        value={endDate}
                        onChange={value => setEndDate(value)}
                        label="End Date (Auction Close)"
                        formatStyle="medium"
                    />
                    
                </div>
                
                <Select
                        label="Select Location"
                        options={buildingList}
                        style={containerStyles}
                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                        onChange={(e) => handleSelect(e)}
                    />
                <CheckboxToggle
                    id="checkbox-toggle-specials"
                    label="Include Specials"
                    value={includeSpecials}
                    onChange={(e) => setIncludeSpecials(!includeSpecials)}
                />
                <CheckboxToggle
                    id="checkbox-toggle-pallets"
                    label="Include Pallet"
                    value={includePallets}
                    onChange={(e) => setIncludePallets(!includePallets)}
                />
                <Button label="GO" onClick={() => handleClick()} variant="neutral" className="rainbow-m-around_medium" />
            </div>
    </section>
    <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
        <Tile
                title="Total Auctions"
                label={totalAuctions}
                 />
        <Tile
                title="Total Cost"
                label={totalAuctionCost}
                 />
        <Tile
                title="Total Revenue"
                label={totalAuctionRevenue}
                 />
        <Tile
                title="Total Profit"
                label={totalAuctionProfit}
                 />
        <Tile
                title="Total Invoiced"
                label={totalAuctionInvoiced}
                 />
    
    </section>
    
    <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
        <Tile
                title="Total Lots"
                label={totalLots}
                 />
        <Tile
                title="Avg Lot Revenue"
                label={avgLotRevenue}
                 />
        <Tile
                title="Avg Lot Retail"
                label={avgAuctionRetail}
                 />
        <Tile
                title="Recovery"
                label={avgRecovery}
                 />
        
    
    </section>
    <section className="react-rainbow-admin-followers_section rainbow-align-content_space-between rainbow-p-top_large">
        <Tile
                title="Avg Auction Revenue"
                label={avgAuctionRevenue}
                 />
        <Tile
                title="Avg Auction Cost"
                label={avgAuctionCost}
                 />
        <Tile
                title="Avg Auction Profit"
                label={avgAuctionProfit}
                 />
        <Tile
                title="ROI"
                label={totalROI}
                 />
    </section>
    {auctionData && chartRevenueData ? 
    <section className="react-rainbow-admin-orders_cards-container">
        <Card
            className="react-rainbow-admin-full_card"
            title="Weekly Totals"
            >
            <h1 className="react-rainbow-admin-users_card-title">BY AUCTION CLOSING DATE</h1>
            <div className="react-rainbow-admin-full_chart">
                <Chart
                    labels={chartLabels}
                    type="line"
                    showLegend={true}
                    maintainAspectRatio={false}>
                    <Dataset title="Retail Value" values={chartRetailData} borderColor="#CCCCCC" backgroundColor="#CCCCCC" />
                    <Dataset title="Cost" values={chartCostData} borderColor="#fe4849" backgroundColor="#fe4849" />
                    <Dataset title="Revenue" values={chartRevenueData} borderColor="#01b6f5" backgroundColor="#01b6f5" />
                    <Dataset title="Profit" values={chartProfitData} borderColor="#8ef4da" backgroundColor="#8ef4da" />
                </Chart>
            </div>
        </Card>
    </section> : <></> }
    {auctionData && chartRevenueData ? 
    <section className="react-rainbow-admin-orders_cards-container">
        <Card
            className="react-rainbow-admin-full_card"
            title="Weekly Recovery & Cost Ratios"
            >
            <h1 className="react-rainbow-admin-users_card-title">BY AUCTION CLOSING DATE</h1>
            <div className="react-rainbow-admin-full_chart">
                <Chart
                    labels={chartLabels}
                    type="line"
                    showLegend={true}
                    maintainAspectRatio={false}>
                    <Dataset title="Revenue Over Retail" values={chartRecoveryData} borderColor="#01b6f5" backgroundColor="#01b6f5" />
                    <Dataset title="Cost Over Retail" values={chartCostRateData} borderColor="#fe4849" backgroundColor="#fe4849" />
                </Chart>
            </div>
        </Card>
    </section> : <></> }
    {retailBreakdownData && breakdownChartData ? 
    <section className="react-rainbow-admin-orders_cards-container">
        <Card
            className="react-rainbow-admin-full_card"
            title="Retail Price Breakdown"
            >
            <h1 className="react-rainbow-admin-users_card-title">Click on the Legend to Toggle Datasets</h1>
            <div className="react-rainbow-admin-full_chart">
                <Chart
                    labels={breakdownChartLabels}
                    type="bar"
                    showLegend={true}
                    maintainAspectRatio={false}>
                    <Dataset title="Total Lots" values={breakdownChartData} borderColor="#fe4849" backgroundColor="#fe4849" />
                    <Dataset title="Average Recovery Rate" values={breakdownChartRecoveryData} borderColor="#01b6f5" backgroundColor="#01b6f5" />
                    <Dataset title="Total Bid Price" values={breakdownChartBidData} borderColor="#8ef4da" backgroundColor="#8ef4da" />
                    <Dataset title="Average Bid Price" values={breakdownChartAvgBidData} borderColor="#CCCCCC" backgroundColor="#CCCCCC" />
                </Chart>
            </div>
        </Card>
    </section> : <></> }
</>);

}
export default ChartsSection;